import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class ReactSlickDemo extends React.Component {
  constructor(props) {
    super(props);


    this.convertWidthToSlides = (w) => {
      if (w > 1400) return 6;
      if (w > 1000) return 5;
      if (w > 800) return 4;
      if (w > 600) return 3;
      if (w > 300) return 2;
    };

    this.state = {
      clients: props.data,
      slides: this.convertWidthToSlides(window.innerWidth),
    };

    window.addEventListener('resize', (e) => {
      this.setState((s) => ({
        ...s,
        slides: this.convertWidthToSlides(e.target.innerWidth),
      }));
    });
  }


  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: this.state.slides,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToScroll: 1,
    };
    return (
      <Slider {...settings}>
        {this.state.clients.map((c) => (
          <div key={c.title}>
            <img style={{ width: '170px', heigth: '100px' }} src={c.logo} alt={c.title} />
          </div>
        ))}
      </Slider>
    );
  }
}

export default ReactSlickDemo;
