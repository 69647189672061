import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Map from './map';
import Carousel from './carousel';

function Index({ header }) {
  const [data, setData] = useState({ services: [], clients: [] });

  useEffect(() => {
    async function init() {
      const { data: uServices } = await axios.get('https://wp-react-sandbox.api.developion.at/wp-json/wp/v2/my_services');
      const { data: uClients } = await axios.get('https://wp-react-sandbox.api.developion.at/wp-json/wp/v2/clients');
      const services = uServices.map((s) => s.acf).reverse();
      const clients = uClients.map((c) => ({ title: c.title.rendered, logo: c.acf.logo }));
      setData({ services, clients });
    }
    init();
    document.title = 'Gardowsky – Beratung und Begleitung für die erfolgreiche Umsetzung komplexer digitaler Projekte';
  }, []);
  return (
    <div className="indexPage">
      <div className={`loadingOverlay ${header.main_image ? 'none' : ''}`} />
      <div className="headDiv">
        <img src={header.main_image} alt="hero" />
        <div className="imageOverlay">
          <img src={header.rounded_image} alt="Round icon" />
          <h3>{header.headline}</h3>
          <p>{header.full_name}</p>
          <img src={header.badge} alt="Badge" />
        </div>
      </div>
      <div className="services">
        {data.services.map((s, i) => (
          <div className={`${s.reverse_order ? 'reverse' : 'row'} service`} key={i}>
            <div className="serviceText">
              <h3>{s.title}</h3>
              <p>{s.text_content}</p>
            </div>
            <img src={s.service_image} alt="service1" />
          </div>
        ))}
      </div>
      <h3 style={{ textAlign: 'center' }}>In den letzten Jahren war ich unter Anderem tätig für:</h3>
      <div>
        {data.clients.length !== 0 && <Carousel data={data.clients} />}
      </div>
      <div className="blackBox">
        <h2>Klingt gut? Dann machen wir das.</h2>
        <div className="triangle" />
      </div>
      <div className="contact">
        <div className="contactImage">
          <img src={header.picture} alt="hero" />
        </div>
        { header.full_name && (
        <div className="infoAndMaps">
          <h3>{header.full_name}</h3>
          <span>{header.phone_number}</span>
          <span><a href={`mailto:${header.email}`}>{header.email}</a></span>
          <span style={{ marginBottom: '2rem' }}>{header.address}</span>
          <Map lat={Number(header.latitutde)} long={Number(header.longitude)} />
        </div>
        )}
      </div>
    </div>
  );
}

export default Index;
