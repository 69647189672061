import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function Pages() {
  const { id } = useParams();
  const [content, setContent] = useState('');

  useEffect(() => {
    if (id) {
      axios.get(`https://wp-react-sandbox.api.developion.at/wp-json/wp/v2/pages/${id}`).then(({ data }) => {
        setContent(data?.content?.rendered || '');
        document.title = data?.title?.rendered || 'Gardowsky – Beratung und Begleitung für die erfolgreiche Umsetzung komplexer digitaler Projekte'
      }).catch(e => {
        setContent('<h1>Page not found</h1>');
      })
    }
    
  }, [id]);

  return (<div style={{width: '100%', margin: '3rem 0'}} dangerouslySetInnerHTML={{ __html: content }} />);
}

export default Pages;
