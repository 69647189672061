import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import axios from 'axios';
import Index from './components/index';
import Pages from './components/pages';
import './App.scss';

function App() {
  const [pdata, setPdata] = useState({pages:[], index: {}});

  useEffect(() => {
    async function init() {
      const { data } = await axios.get('https://wp-react-sandbox.api.developion.at/wp-json/wp/v2/pages');
      const indexIndex = data.findIndex(v => v.slug === 'index')
      const slicedIndex = data.splice(indexIndex, 1);
      setPdata({
        index: slicedIndex.length > 0 ? slicedIndex[0].acf : false,
        pages: data.reverse() // wtf
      })
    }
    init();
  }, []);

  return (
    <div className="App">
      <Router>
        <div className="logo">
          <Link to="/"><img src={pdata.index.logo} alt="logo" /></Link>
        </div>
        <Switch>
          <Route path="/pages/:id">
            <Pages className="pages" />
          </Route>
          <Route path="/">
            <Index header={pdata.index} />
          </Route>
        </Switch>
        <footer>
          <p>
            {`Copyright ${new Date().getFullYear()} |`}
          </p>
          <p>
          All Rights Reserved |
          </p>
          {pdata.pages.map(page => <p key={page.id}><Link to={`/pages/${page.id}`}> {page?.title?.rendered}</Link> |</p>)}
          <p>
            <a target="_blank" rel="noopener noreferrer" href="https://www.wko.at/branchen/information-consulting/unternehmensberatung-buchhaltung-informationstechnologie/it-dienstleistung/Allgemeine_Geschaeftsbedingungen_.html">
            AGB
            </a>
          </p>
        </footer>
      </Router>
    </div>
  );
}

export default App;
