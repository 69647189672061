import React from 'react';
import {
  Map, Marker, Popup, TileLayer,
} from 'react-leaflet';

function MapComponent({ lat, long}) {
  return (
    <Map center={[lat, long]} zoom={18}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[lat, long]}>
        <Popup>
            A pretty CSS3 popup.
          <br />
            Easily customizable.
        </Popup>
      </Marker>
    </Map>
  );
}

export default MapComponent;
